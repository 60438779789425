import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { ProductActions, SessionActions } from "../redux/actions";
import { v4 as uuid } from "uuid";
import { CookieActions } from "../redux/actions/cookieActions";
import { logEvent, setUserProperties, trackWindowDataLayer } from "../tracker";

interface ModalContextInterface {
  showModal: boolean;
  setModal: (showModal: boolean) => void;
  lengthUnit: "cm" | "ft";
  setLengthUnit: (lengthUnit: "cm" | "ft") => void;
  weightUnit: "kg" | "lbs";
  setWeightUnit: (weightUnit: "kg" | "lbs") => void;
  sessionId: string;
  loadTool: string;
  productCode: string;
}

export const ModalContext = React.createContext<ModalContextInterface>({
  showModal: false,
  setModal: () => undefined,
  lengthUnit: "cm",
  setLengthUnit: () => undefined,
  weightUnit: "kg",
  setWeightUnit: () => undefined,
  sessionId: "",
  loadTool: "",
  productCode: "",
});

// Helper functions for initial state
const getInitialLengthUnit = (): "cm" | "ft" =>
  (localStorage.getItem("saiz-length-unit") as "cm" | "ft") || "cm";

const getInitialWeightUnit = (): "kg" | "lbs" =>
  (localStorage.getItem("saiz-weight-unit") as "kg" | "lbs") || "kg";

const ModalContextProvider = (props: any) => {
  const {
    children,
    session,
    brandCode,
    lang,
    productCode,
    auth,
    product,
    visitorId,
  } = props;

  const dispatch = useDispatch<any>();
  const { i18n } = useTranslation("common");

  // State variables
  const [showModal, setModal] = useState<boolean>(false);
  const [sessionProductCode, setSessionProductCode] = useState<string>(
    localStorage.getItem("saiz-widget-productcode") ?? productCode
  );
  const [sessionCookie, setSessionCookie] = useState<string>(
    localStorage.getItem("saiz-widget-cookie-flag") ?? "active"
  );
  const [sessionId] = useState<string>(uuid());
  const [loadSaizTool, setLoadSaizTool] = useState<string>(uuid());
  const [lengthUnit, setLengthUnit] = useState<"cm" | "ft">(
    getInitialLengthUnit
  );
  const [weightUnit, setWeightUnit] = useState<"kg" | "lbs">(
    getInitialWeightUnit
  );

  // Memoized functions
  const handleSessionProductCodeChange = useCallback(() => {
    setLoadSaizTool(uuid());
    const storedProductCode = localStorage.getItem("saiz-widget-productcode");
    if (storedProductCode && storedProductCode !== sessionProductCode) {
      setSessionProductCode(storedProductCode);
    }
  }, [sessionProductCode]);

  const handleSessionCookieChange = useCallback(() => {
    const storedCookie = localStorage.getItem("saiz-widget-cookie-flag");
    if (storedCookie) {
      setSessionCookie(storedCookie);
    }
  }, []);

  // Effect: Handle language changes
  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang.toLowerCase());
    }
  }, [lang, i18n]);

  // Effect: Sync length and weight units with localStorage
  useEffect(() => {
    localStorage.setItem("saiz-length-unit", lengthUnit);
  }, [lengthUnit]);

  useEffect(() => {
    localStorage.setItem("saiz-weight-unit", weightUnit);
  }, [weightUnit]);

  // Effect: Initialize session and fetch cookie data
  useEffect(() => {
    if (!session.isLoaded) {
      dispatch(SessionActions.setSession(sessionId));
    }
    dispatch(CookieActions.getCookie(brandCode));
  }, [brandCode]);

  // Effect: Handle session product code and cookie changes
  useEffect(() => {
    window.addEventListener(
      "saiz-widget-storage",
      handleSessionProductCodeChange
    );
    window.addEventListener("saiz-widget-cookie", handleSessionCookieChange);

    return () => {
      window.removeEventListener(
        "saiz-widget-storage",
        handleSessionProductCodeChange
      );
      window.removeEventListener(
        "saiz-widget-cookie",
        handleSessionCookieChange
      );
    };
  }, [handleSessionProductCodeChange, handleSessionCookieChange]);

  // Effect: Track product initialization
  useEffect(() => {
    const eventProperties = {
      ...auth?.localStorage,
      brandCode,
      productCode,
      session_id: sessionId,
      visitorId,
      user_id: visitorId,
    };

    trackWindowDataLayer(
      "saiz_Recommendation_Product_Initialization",
      eventProperties
    );
    logEvent("saiz_Recommendation_Product_Initialization", "", eventProperties);
    setUserProperties(sessionId, eventProperties);

    if (
      sessionCookie === "active" &&
      sessionStorage.getItem("saiz-widget-onload") === "loaded" &&
      !sessionProductCode?.includes(product?.product?.productCode)
    ) {
      dispatch(
        ProductActions.getProductDetail(
          sessionId,
          brandCode,
          lang,
          sessionProductCode,
          visitorId,
          auth
        )
      );
    }
  }, [sessionProductCode]);

  // Memoized context value
  const contextValue = {
    showModal,
    setModal,
    lengthUnit,
    setLengthUnit,
    weightUnit,
    setWeightUnit,
    sessionId,
    loadTool: loadSaizTool,
    productCode: sessionProductCode,
  };

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  );
};

// Map Redux state to props
const mapStateToProps = (state: any) => ({
  auth: state.AuthReducer,
  session: state.SessionReducer,
  cookie: state.CookieReducer,
  product: state.ProductReducer,
});

export default connect(mapStateToProps)(ModalContextProvider);
