import React, { useEffect, useState, useCallback } from "react";
import { BrandConstants } from "../models";
import { connect } from "react-redux";

import {
  BognerLinkContent,
  CommonLinkContent,
  DryKornLinkContent,
  MopLinkContent,
  SOLinkContent,
  JwLinkContent,
} from "./brandLinks";

interface SaizLinkProps {
  brandCode: string;
  productCode: string;
  lang: string;
  [key: string]: any; // Additional props from Redux
}

const SaizLink: React.FC<SaizLinkProps> = (props) => {
  const { brandCode, productCode, lang, cal } = props;

  // State to store the link content
  const [linkContent, setLinkContent] = useState<React.ReactNode>(null);

  // Memoized function to determine the appropriate link content
  const getLink = () => {
    switch (brandCode) {
      case BrandConstants.MARCOPOLO:
        return <MopLinkContent {...props} />;
      case BrandConstants.SOLIVER:
      case BrandConstants.COMMA:
      case BrandConstants.QSOLIVER:
        return <SOLinkContent {...props} />;
      case BrandConstants.BOGNER:
      case BrandConstants.FIREICE:
        return <BognerLinkContent {...props} />;
      case BrandConstants.DRYKORN:
        return <DryKornLinkContent {...props} />;
      case BrandConstants.JACKWOLFSKIN:
        return <JwLinkContent {...props} />;
      default:
        return <CommonLinkContent {...props} />;
    }
  };

  return getLink();
};

// Map Redux state to component props
const mapStateToProps = (state: any) => ({
  product: state.ProductReducer,
  auth: state.AuthReducer,
  cal: state.CalculatorReducer,
  cookie: state.CookieReducer,
  session: state.SessionReducer,
});

export default connect(mapStateToProps)(SaizLink);
