import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ModalContext } from "../../context/modalContext";
import { BrandConstants, ScreenTypeDto } from "../../models";

export const CommonLinkContent = (props: any) => {
  const {
    cal,
    product,
    getMeasurements,
    auth,
    setStep,
    setReturnToScreen,
    target,
    showModal,
    onOpen,
    isOpen,
    brandCode,
    saizEvents,
    productCode,
    lang,
  } = props;
  const { setModal } = useContext(ModalContext);
  const [visible, setVisible] = useState(true);
  const { t, i18n } = useTranslation("common");
  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang, i18n]);
  useEffect(() => {
    const handleScroll = () => {
      const target = document
        ?.querySelector(`h2[class='ctl-title']`)
        ?.getBoundingClientRect().top;

      if (window.innerWidth > 600 && target) {
        setVisible(target > 450);
      }
    };
    if (brandCode === BrandConstants.RICHROYALDE) {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [productCode, product, cal]);
  return (
    <>
      {product?.isLoaded && product?.product?.isActive && !showModal && (
        <div
          ref={(node) => {
            node?.style.setProperty(
              "display",
              visible ? "flex" : "none",
              "important"
            );
          }}
          onClick={() => {
            if (cal?.data?.data?.measuredSize) {
              if (!cal?.isLoaded) {
                getMeasurements();
              }
              setStep(ScreenTypeDto.Summary);
              saizEvents(ScreenTypeDto.Summary);
              setReturnToScreen(ScreenTypeDto.Summary);
            } else {
              setStep(ScreenTypeDto.ScreenOne);
              saizEvents(ScreenTypeDto.ScreenOne);
              setReturnToScreen(ScreenTypeDto.ScreenOne);
            }
            setModal(true);
          }}
          id="saiz-floating-button"
          className={`slide-in ${target ? "saiz-block-button" : "saiz-fixed"}`}
        >
          <div>
            {!cal?.isLoaded || cal?.data?.noSize ? (
              <>
                <span
                  style={{
                    fontWeight: "var(--saiz_link_desktop_font_weight)",
                  }}
                  className="saiz-button-calculate"
                >
                  {t("calculateSize")}
                </span>
                <span className="saiz-button-calculate-mobile">
                  {product?.product?.brandCode?.toLowerCase() === "bonita"
                    ? t("calculateSize")
                    : t("calculateSizeMobile")}
                </span>
              </>
            ) : (
              <>
                <span className="saiz-best-size">
                  {cal?.data?.data?.measuredSize}
                </span>
                <span className="saiz-best-size-text"> {t("isYourBest")}</span>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
