import React, { useContext, useEffect, useCallback, useState } from "react";
import { ModalContext } from "../../context/modalContext";
import { ScreenTypeDto } from "../../models";
import { useTranslation } from "react-i18next";

export const JwLinkContent = (props: any) => {
  const {
    cal,
    getMeasurements,
    setStep,
    setReturnToScreen,
    saizEvents,
    showModal,
    brandCode,
    product,
    lang,
    productCode,
  } = props;
  const { setModal } = useContext(ModalContext);
  const [visible, setVisible] = useState(true);

  const { t, i18n } = useTranslation("common");
  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang, i18n]);
  useEffect(() => {
    if (product?.product?.isActive) {
      updateContent();
    } else {
      const recommenderLink = document.getElementById("jwsaizlink");
      const seperator = document.getElementById("jwsaizseperator");
      if (recommenderLink) {
        recommenderLink.remove();
      }
      if (seperator) {
        seperator.remove();
      }
    }
  }, [lang, productCode, product, cal]);

  const openModel = useCallback(() => {
    setModal(true);
    if (cal?.data?.data?.measuredSize) {
      if (!cal?.isLoaded) {
        getMeasurements();
      }
      setStep(ScreenTypeDto.Summary);
      saizEvents(ScreenTypeDto.Summary);
      setReturnToScreen(ScreenTypeDto.Summary);
    } else {
      setStep(ScreenTypeDto.ScreenOne);
      saizEvents(ScreenTypeDto.ScreenOne);
      setReturnToScreen(ScreenTypeDto.ScreenOne);
    }
  }, []);

  const updateContent = useCallback(() => {
    const text =
      !cal?.isLoaded || cal?.data?.noSize
        ? t("question")
        : t("answer") + " " + cal?.data?.data?.measuredSize;
    const target = document.querySelector(
      "button[data-open-guide='fitGuidePicker']"
    );
    const targetSeperator = document.querySelector(
      "div.jwsdw-fitting-line.jws-displayInlineBlock"
    );
    const parentTarget = document.querySelector(
      "div.jws-margin-top-2.jws-margin-medium-top-0"
    );
    if (parentTarget) {
      const recommenderLink = document.getElementById("jwsaizlink");
      const linkElement = document.createElement("a");
      linkElement.href = "#";
      linkElement.innerHTML = text;
      linkElement.classList.add(
        "jwsdw-pdp-sizeGuideLink",
        "jws-copy1NanoTitle",
        "jws-colorBlack",
        "jws-textlink",
        "jwsdw-near-fitting"
      );
      linkElement.style.marginLeft = "auto";
      linkElement.id = "jwsaizlink";
      linkElement.addEventListener("click", (event) => {
        event.preventDefault();
        openModel();
      });
      const seperator = document.createElement("div");
      seperator.classList.add("jwsdw-fitting-line", "jws-displayInlineBlock");
      seperator.id = "jwsaizseperator";
      if (!recommenderLink) {
        parentTarget?.insertAdjacentElement("afterbegin", seperator);
        parentTarget?.insertAdjacentElement("afterbegin", linkElement);

        if (!target?.nextElementSibling) {
          target?.parentNode?.insertBefore(
            target,
            target.previousElementSibling
          );
          target?.parentNode?.insertBefore(
            target,
            target.previousElementSibling
          );
          targetSeperator?.parentNode?.insertBefore(
            targetSeperator,
            targetSeperator.previousElementSibling
          );
        }
      }
      updateLink();
    }
  }, [lang, productCode, product, cal]);

  const updateLink = useCallback(() => {
    // Update the text of the link element with the new size
    const linkElement = document.getElementById("jwsaizlink");
    const text =
      !cal?.isLoaded || cal?.data?.noSize
        ? t("question")
        : t("answer") + " " + cal?.data?.data?.measuredSize;
    if (linkElement) {
      linkElement.innerHTML = text;
    }
    const sizeButtons = document.querySelectorAll(".jwsdw-product-swatch");
    // Find the button matching the default size
    sizeButtons.forEach((btn: any) => {
      const span = btn.querySelector(".swatch-name");
      if (
        span &&
        span.textContent?.trim()?.toLowerCase() ===
          cal?.data?.data?.measuredSize?.toLowerCase()
      ) {
        btn.click(); // Simulate a click event on the button
      }
    });
  }, [lang, productCode, product, cal]);

  return (
    <>
      {product?.isLoaded && product?.product?.isActive && !showModal && (
        <div
          ref={(node) => {
            node?.style.setProperty(
              "display",
              visible ? "flex" : "none",
              "important"
            );
          }}
          onClick={() => {
            if (cal?.data?.data?.measuredSize) {
              if (!cal?.isLoaded) {
                getMeasurements();
              }
              setStep(ScreenTypeDto.Summary);
              saizEvents(ScreenTypeDto.Summary);
              setReturnToScreen(ScreenTypeDto.Summary);
            } else {
              setStep(ScreenTypeDto.ScreenOne);
              saizEvents(ScreenTypeDto.ScreenOne);
              setReturnToScreen(ScreenTypeDto.ScreenOne);
            }
            setModal(true);
          }}
          id="saiz-floating-button"
          className={`slide-in saiz-block-button`}
        >
          <div>
            {!cal?.isLoaded || cal?.data?.noSize ? (
              <>
                <span
                  style={{
                    fontWeight: "var(--saiz_link_desktop_font_weight)",
                  }}
                  className="saiz-button-calculate"
                >
                  {t("calculateSize")}
                </span>
                <span className="saiz-button-calculate-mobile">
                  {product?.product?.brandCode?.toLowerCase() === "bonita"
                    ? t("calculateSize")
                    : t("calculateSizeMobile")}
                </span>
              </>
            ) : (
              <>
                <span className="saiz-best-size">
                  {cal?.data?.data?.measuredSize}
                </span>
                <span className="saiz-best-size-text"> {t("isYourBest")}</span>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
