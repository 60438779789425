import React, { useContext, useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ModalContext } from "../../context/modalContext";
import { ScreenTypeDto } from "../../models";
import useSpinnerObserver from "./bognerMutation";

export const BognerLinkContent = (props: any) => {
  const {
    cal,
    getMeasurements,
    setStep,
    setReturnToScreen,
    saizEvents,
    product,
    productCode,
    lang,
  } = props;
  const { setModal } = useContext(ModalContext);
  const { t, i18n } = useTranslation("common");
  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang, i18n]);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      updateContent();
    }, 200);
    return () => clearTimeout(debounceTimeout);
  }, [reload, productCode, cal, product]);
  const openModel = useCallback(() => {
    if (cal?.data?.data?.measuredSize) {
      if (!cal?.isLoaded) {
        getMeasurements();
      }
      setStep(ScreenTypeDto.Summary);
      saizEvents(ScreenTypeDto.Summary);
      setReturnToScreen(ScreenTypeDto.Summary);
    } else {
      setStep(ScreenTypeDto.ScreenOne);
      saizEvents(ScreenTypeDto.ScreenOne);
      setReturnToScreen(ScreenTypeDto.ScreenOne);
    }
    setModal(true);
  }, []);

  const updateContent = useCallback(() => {
    const Svg =
      '<svg xmlns="http://www.w3.org/2000/svg" style="margin-right:2px; margin-left:8px; margin-top:8px;" name="recommender_svg" width="17" height="16" viewBox="0 0 21 20" fill="none"><g clip-path="url(#clip0_2615_14223)"><path d="M17.2471 8.7914C16.9987 8.7914 16.7955 8.59785 16.7955 8.36129V4.56774H12.3878C12.1394 4.56774 11.9362 4.37419 11.9362 4.13763C11.9362 3.90108 12.1394 3.70753 12.3878 3.70753H17.2471C17.4955 3.70753 17.6987 3.90108 17.6987 4.13763V8.36129C17.6987 8.59785 17.4955 8.7914 17.2471 8.7914Z" fill="black"/><path d="M17.2471 16.2925H12.3878C12.1394 16.2925 11.9362 16.0989 11.9362 15.8624C11.9362 15.6258 12.1394 15.4323 12.3878 15.4323H16.7955V11.6387C16.7955 11.4022 16.9987 11.2086 17.2471 11.2086C17.4955 11.2086 17.6987 11.4022 17.6987 11.6387V15.8624C17.6987 16.0989 17.4955 16.2925 17.2471 16.2925Z" fill="black"/><path d="M8.61224 16.2925H3.75288C3.5045 16.2925 3.30127 16.0989 3.30127 15.8624V11.6387C3.30127 11.4022 3.5045 11.2086 3.75288 11.2086C4.00127 11.2086 4.2045 11.4022 4.2045 11.6387V15.4323H8.61224C8.86062 15.4323 9.06385 15.6258 9.06385 15.8624C9.06385 16.0989 8.86062 16.2925 8.61224 16.2925Z" fill="black"/><path d="M3.75288 8.7914C3.5045 8.7914 3.30127 8.59785 3.30127 8.36129V4.13763C3.30127 3.90108 3.5045 3.70753 3.75288 3.70753H8.61224C8.86062 3.70753 9.06385 3.90108 9.06385 4.13763C9.06385 4.37419 8.86062 4.56774 8.61224 4.56774H4.2045V8.36129C4.2045 8.59785 4.00127 8.7914 3.75288 8.7914Z" fill="black"/><path d="M20.5484 20H0.451613C0.203226 20 0 19.8065 0 19.5699V0.430108C0 0.193548 0.203226 0 0.451613 0H20.5484C20.7968 0 21 0.193548 21 0.430108V19.5699C21 19.8065 20.7968 20 20.5484 20ZM0.903226 19.1398H20.0968V0.860215H0.903226V19.1398Z" fill="black"/></g>  <defs>  <clipPath id="clip0_2615_14223"><rect width="21" height="20" fill="white"/></clipPath></defs></svg>';

    const target = document.querySelector(
      "button.productDetailsCockpitColBottom__fitFinder.js-fitFinderTrigger"
    );
    const parentTarget = document.querySelector(
      "span.productDetailsCockpitColBottom__fitFinderWrapper"
    );
    const recommenderSvg = document.querySelector(
      "svg[name='recommender_svg']"
    );
    if (target && parentTarget) {
      const text =
        !cal?.isLoaded || cal?.data?.noSize
          ? t("question")
          : t("answer") + " " + cal?.data?.data?.measuredSize;
      if (product?.product?.isActive) {
        if (!recommenderSvg) {
          parentTarget.insertAdjacentHTML("beforebegin", Svg);
          const linkElement = document.createElement("a");
          linkElement.href = "#";
          linkElement.id = "saizbognerlink";
          linkElement.innerText = text;
          linkElement.classList.add(
            "productDetailsCockpitColBottom__sizeTable"
          );
          linkElement.addEventListener("click", (event) => {
            event.preventDefault();
            openModel();
          });
          parentTarget.appendChild(linkElement);
        }
      } else {
        recommenderSvg?.remove();
        target.innerHTML = "";
      }
      updateLink();
    }
  }, [lang, productCode, product, cal]);
  const handleAttributeChange = useCallback(() => {
    setReload((prev) => !prev);
  }, []);
  const updateLink = useCallback(() => {
    const target = document.getElementById("saizbognerlink");
    const text =
      !cal?.isLoaded || cal?.data?.noSize
        ? t("question")
        : t("answer") + " " + cal?.data?.data?.measuredSize;
    if (target) {
      target.innerText = text;
    }
  }, [lang, productCode, product, cal]);

  useEffect(() => {
    updateLink();
  }, [lang, productCode, product, cal]);

  useSpinnerObserver(handleAttributeChange);
  return <></>;
};
