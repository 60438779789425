import React, { Suspense } from "react";
import { ChakraProvider, theme } from "@chakra-ui/react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { defaultTheme } from "./themes/theme";
import { AuthLayout } from "./layout";
import ModalContextProvider from "./context/modalContext";
import II8n from "./i18n";
import { store, persistor } from "./redux/store/store";
import { GlobalStyles } from "./themes/global";
import "./styles/style.scss";
import App from "./App";
import { cookiesListner } from "./redux/actions/cookieActions";
import { ThemeProvider } from "styled-components";
import { BrandConstants } from "./models";
import {
  initializeTagManager,
  logPageView,
  trackWindowDataLayer,
} from "./tracker";
import { dispatchCustomEvents } from "./helpers";

// Constants
const SAIZ_RECOMMENDER_KEY = "saiz-recommender";
const SAIZ_WIDGET_ONLOAD_KEY = "saiz-widget-onload";

interface SaizWidgetProps {
  id: string;
  brandCode: string;
  lang: string;
  productCode: string;
  visitorId?: string;
}

interface SaizWidgetState {
  id: string;
  brandCode: string;
  lang: string;
  productCode: string;
  visitorId: string | null;
}

class SaizWidget extends React.Component<SaizWidgetProps, SaizWidgetState> {
  constructor(props: SaizWidgetProps) {
    super(props);

    const brandCode = props.brandCode?.replace("+", "")?.toUpperCase();
    const lang = this.getLanguage(props.lang, brandCode);

    this.state = {
      id: props.id,
      brandCode,
      productCode: props.productCode,
      lang,
      visitorId:
        props.visitorId ?? localStorage.getItem("saiz_widget_visitorid"),
    };

    this.initializeWidget();
  }

  // Determine the language based on brand and input language
  getLanguage(lang: string, brandCode: string): string {
    if (brandCode === BrandConstants.ETERNA) {
      const langMap: Record<string, string> = {
        be: "fr",
        dk: "en",
        es: "en",
        ie: "en",
      };
      return langMap[lang?.toLowerCase()] || lang;
    }
    return lang;
  }

  // Initialize widget settings
  initializeWidget() {
    II8n(this.state.brandCode);
    delete theme.styles.global; // Ensure no global styles conflict
    cookiesListner(this.state.brandCode);
  }

  // Lifecycle method: Component mounted
  componentDidMount() {
    sessionStorage.setItem(SAIZ_WIDGET_ONLOAD_KEY, "loaded");
    const eventProperties = {
      brandCode: this.state.brandCode,
      productCode: this.state.productCode,
      session_id: localStorage.getItem("saiz_session_id"),
      visitorId: this.state.visitorId,
      lang: this.state.lang,
      user_id: this.state.visitorId,
    };

    // Track initialization and dispatch custom events
    trackWindowDataLayer("saiz_Recommender_Initialization", eventProperties);
    dispatchCustomEvents(eventProperties);

    // Check for version updates
    this.checkForVersionUpdate();

    // Initialize tag manager and log page view
    initializeTagManager(this.state.brandCode);
    logPageView();
  }

  // Check if a new version is available
  checkForVersionUpdate() {
    const currentVersion = process.env.REACT_APP_VERSION ?? "unknown";
    const storedVersion = sessionStorage.getItem(SAIZ_RECOMMENDER_KEY);

    if (storedVersion !== currentVersion) {
      console.log("saiz-recommender", currentVersion, "New Version Available!");
      sessionStorage.setItem(SAIZ_RECOMMENDER_KEY, currentVersion);
    }
  }

  render() {
    return (
      <ThemeProvider theme={{ ...this.state }}>
        <GlobalStyles />
        <AuthLayout {...this.state}>
          <ModalContextProvider {...this.state}>
            <App {...this.state} />
          </ModalContextProvider>
        </AuthLayout>
      </ThemeProvider>
    );
  }
}

// Main App Wrapper with Redux and ChakraProvider
export const AppWithCallbackAfterRender = (props: any) => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Suspense fallback={null}>
        <ChakraProvider
          theme={defaultTheme}
          resetCSS={false}
          cssVarsRoot="#saiz-modal-container"
          disableGlobalStyle={true}
          resetScope="#saiz-modal-container"
          key="saiz"
        >
          <SaizWidget id="saiz-widget-container" {...props} />
        </ChakraProvider>
      </Suspense>
    </PersistGate>
  </Provider>
);
