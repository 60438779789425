import React, { useContext, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ModalContext } from "../../context/modalContext";
import { ScreenTypeDto } from "../../models";

export const DryKornLinkContent = (props: any) => {
  const {
    cal,
    getMeasurements,
    setStep,
    setReturnToScreen,
    saizEvents,
    product,
    productCode,
    lang,
  } = props;
  const { setModal } = useContext(ModalContext);
  const { t, i18n } = useTranslation("common");
  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang, i18n]);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      updateContent();
    }, 200);
    return () => clearTimeout(debounceTimeout);
  }, [lang, productCode, product, cal]);

  const openModel = useCallback(() => {
    setModal(true);
    if (cal?.data?.data?.measuredSize) {
      if (!cal?.isLoaded) {
        getMeasurements();
      }
      setStep(ScreenTypeDto.Summary);
      saizEvents(ScreenTypeDto.Summary);
      setReturnToScreen(ScreenTypeDto.Summary);
    } else {
      setStep(ScreenTypeDto.ScreenOne);
      saizEvents(ScreenTypeDto.ScreenOne);
      setReturnToScreen(ScreenTypeDto.ScreenOne);
    }
  }, []);

  const updateContent = useCallback(() => {
    const peerTargets = document.querySelectorAll(
      "a[class='buy-widget-size-guide-link']"
    );
    const recommenderLinkElements = document.querySelectorAll(
      "a[class='buy-widget-size-guide-link saizdrykornlink']"
    );
    const parentTargets = document.querySelectorAll<HTMLElement>(
      "div[class='buy-widget-size-guide mb-2']"
    );
    parentTargets.forEach((target, key) => {
      target.style.gap = "5px";
    });
    if (peerTargets) {
      const text =
        !cal?.isLoaded || cal?.data?.noSize
          ? t("question")
          : t("answer") + " " + cal?.data?.data?.measuredSize;
      const linkElement = document.createElement("a");
      linkElement.href = "#";
      linkElement.innerHTML = text;
      linkElement.classList.add(
        "buy-widget-size-guide-link",
        "saizdrykornlink"
      );
      linkElement.style.marginLeft = "auto";
      linkElement.style.textDecoration = "underline";
      linkElement.style.textTransform = "Uppercase";
      const seperator = document.createElement("span");
      seperator.innerHTML = " | ";

      if (product?.product?.isActive) {
        if (!recommenderLinkElements.length) {
          peerTargets.forEach((target, key) => {
            const newLinkElement = linkElement.cloneNode(true) as Element;
            newLinkElement.addEventListener("click", (event) => {
              event.preventDefault();
              openModel();
            });
            target.insertAdjacentElement("beforebegin", newLinkElement);
          });
          peerTargets.forEach((target, key) => {
            const newSeperator = seperator.cloneNode(true) as Element;

            target.insertAdjacentElement("beforebegin", newSeperator);
          });
        }
        updateLink();
      } else {
        linkElement?.remove();
      }
    }
  }, [lang, productCode, product, cal]);

  const updateLink = useCallback(() => {
    const recommenderLinkElements = document.querySelectorAll(
      "a[class='buy-widget-size-guide-link saizdrykornlink']"
    );
    const text =
      !cal?.isLoaded || cal?.data?.noSize
        ? t("question")
        : t("answer") + " " + cal?.data?.data?.measuredSize;
    if (recommenderLinkElements.length) {
      recommenderLinkElements.forEach((target, key) => {
        target.innerHTML = text;
      });
    }
  }, [lang, productCode, product, cal]);
  return <></>;
};
