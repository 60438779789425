import React from "react";
import { createRoot, Root } from "react-dom/client";
import { renderWidgetParamsFromContainer } from "./utils/utils";
import { BrandConstants } from "./models";
import { AppWithCallbackAfterRender } from "./extension";

// Constants
const SAIZ_WIDGET_PRODUCTCODE_KEY = "saiz-widget-productcode";
const SAIZ_WIDGET_ONLOAD_KEY = "saiz-widget-onload";

// Root container for React rendering
let root: Root | null = null;

// Set up the container and render the widget
const setContainer = (container: HTMLDivElement) => {
  const { productCode, brandCode, lang, visitorId } =
    renderWidgetParamsFromContainer(container);

  let pCode = productCode?.replaceAll("#", "-") || "";

  // Handle specific brand logic
  if (
    brandCode?.toUpperCase() === BrandConstants.BOGNER ||
    brandCode?.toUpperCase() === BrandConstants.FIREICE
  ) {
    if (!root) root = createRoot(container);
  } else {
    root = createRoot(container);
  }

  // Fallback for missing product code
  if (!productCode) {
    const urlParts = window.location.href.split("/");
    const urlSegment = urlParts.pop();
    const code = urlSegment ?? "";
    pCode = code.split("-").slice(0, 3).join("-");
  }

  // Store product code in localStorage
  localStorage.setItem(SAIZ_WIDGET_PRODUCTCODE_KEY, pCode);

  // Render the widget
  root.render(
    <AppWithCallbackAfterRender
      productCode={pCode}
      brandCode={brandCode?.toUpperCase()}
      lang={lang}
      visitorId={visitorId}
    />
  );
};

// Trigger a product code update
const pressTrigger = (productCode: string) => {
  localStorage.setItem(SAIZ_WIDGET_PRODUCTCODE_KEY, productCode);
  window.dispatchEvent(new Event("saiz-widget-storage"));
};

// Set up event listeners for widget initialization
const eventHandlers = () => {
  // Handle custom initialization events
  window.addEventListener("saiz-widget-initializer", (event: any) => {
    if (
      event?.detail?.productcode !== "" &&
      localStorage.getItem("saiz-widget-productcode") !==
        event?.detail?.productcode
    ) {
      pressTrigger(event?.detail?.productcode);
    }
    console.log("saiz-widget-initializer", event?.detail?.productcode);
  });

  // Handle widget loader and reload events
  window.addEventListener("saiz-widget-loader", init);
  window.addEventListener("saiz-widget-reload", init);
};

// Initialize the widget
const init = () => {
  const container = document.getElementById("saiz-widget-container");
  if (container) {
    setContainer(container as HTMLDivElement);
  } else {
    window.dispatchEvent(new Event("saiz-widget-reload"));
  }
};
// Initialize the widget and set up event handlers
eventHandlers();

if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", init);
} else {
  init();
}
