import React, { useContext, useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ModalContext } from "../../context/modalContext";
import { ScreenTypeDto } from "../../models";

export const SOLinkContent = (props: any) => {
  const svg = `<svg width="16" height="16" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">...</svg>`;
  const {
    cal,
    getMeasurements,
    setStep,
    setReturnToScreen,
    saizEvents,
    product,
    productCode,
    lang,
  } = props;
  const { setModal } = useContext(ModalContext);
  const { t, i18n } = useTranslation("common");

  // Memoized function to open the modal
  const openModel = () => {
    setModal(true);
    if (cal?.data?.data?.measuredSize) {
      if (!cal?.isLoaded) {
        getMeasurements();
      }
      setStep(ScreenTypeDto.Summary);
      saizEvents(ScreenTypeDto.Summary);
      setReturnToScreen(ScreenTypeDto.Summary);
    } else {
      setStep(ScreenTypeDto.ScreenOne);
      saizEvents(ScreenTypeDto.ScreenOne);
      setReturnToScreen(ScreenTypeDto.ScreenOne);
    }
  };

  // Memoized function to update content
  const updateContent = () => {
    const recommenderSvg = document.querySelector(
      "svg[name='recommender_svg']"
    );
    const target = document.querySelector("div[class='placeholder_for_saiz']");
    const recommenderLinkElements = document.querySelectorAll(
      "a[class='saizSOlink']"
    );

    if (target && product?.product?.isActive) {
      if (!recommenderLinkElements.length) {
        const linkElement = document.createElement("a");
        linkElement.href = "#";
        linkElement.classList.add("saizSOlink");
        linkElement.style.marginLeft = "auto";
        linkElement.style.textDecoration = "underline";
        linkElement.style.fontSize = "12px";
        linkElement.style.display = "flex";
        linkElement.style.alignItems = "center";
        linkElement.style.gap = "4px";

        linkElement.addEventListener("click", (event) => {
          event.preventDefault();
          openModel();
        });
        const text =
          !cal?.isLoaded || cal?.data?.noSize
            ? t("question")
            : t("answer") + " " + cal?.data?.data?.measuredSize;
        linkElement.innerHTML = text;
        target.insertAdjacentElement("afterbegin", linkElement);

        if (!recommenderSvg) {
          linkElement.insertAdjacentHTML("beforeend", svg);
        } else {
          recommenderSvg?.remove();
        }
      }
    } else {
      // Remove existing links if the product is not active
      recommenderLinkElements.forEach((link) => link.remove());
    }
    updateLink();
  };

  // Memoized function to update link text
  const updateLink = () => {
    const recommenderLinkElements = document.querySelectorAll(
      "a[class='saizSOlink']"
    );
    const text =
      !cal?.isLoaded || cal?.data?.noSize
        ? t("question")
        : t("answer") + " " + cal?.data?.data?.measuredSize;

    if (recommenderLinkElements.length) {
      recommenderLinkElements.forEach((link) => {
        link.innerHTML = text;
        link.insertAdjacentHTML("beforeend", svg);
      });
    }
  };

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
    updateContent();
  }, [cal]);

  return null;
};
